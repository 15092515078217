import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import dompurify from "dompurify";
import GridGallery from "../components/GridGallery";
import Layout from "../components/layout";
import SEO from "../components/seo";

const PagesTemplate = ({ data, pageContext }) => {
  if (data && data.thePage) {
    const { slug } = pageContext;
    const { frontmatter, html } = data.thePage;
    const { title, excerpt } = frontmatter;
    const cleanHTML = html;

    const faqQuery = data.allFaqQuestionsJson.edges;

    return (
      <Layout>
        <SEO title={title} description={excerpt} />

        <div id="article">
          <div className="article-markdown">
            <h1 className="article-title">{title}</h1>

            {/* Show only for the FAQ -> loop through the "faq-questions.json" array object */}
            {/faq/.test(slug) && (
              <div id="faq-skip-to-question-wrapper">
                <h2>Skip to Question</h2>
                <ul>
                  {faqQuery.map(({ node }) => {
                    return (
                      <li key={node.id}>
                        <AnchorLink to={`/faq#${node.link}`} title={node.question} stripHash={true} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />

            {frontmatter && frontmatter.galleryImages && <GridGallery images={frontmatter.galleryImages} />}
          </div>
        </div>
      </Layout>
    );
  } else {
    return;
  }
};

export default PagesTemplate;
export const pageQuery = graphql`
  query PagesBySlug($slug: String!) {
    thePage: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        excerpt
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galleryImages {
          relativePath
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
    allFaqQuestionsJson {
      edges {
        node {
          id
          question
          link
        }
      }
    }
  }
`;
