import React, { Fragment } from "react";
import Gallery from "react-grid-gallery";

const GridGallery = ({ images }) => {
  const galleryImages = images.map(object => ({
    src: "/images/" + object.relativePath,
    thumbnail: "/images/" + object.relativePath,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  }));

  return (
    <Fragment>
      <Gallery images={galleryImages} />
    </Fragment>
  );
};

export default GridGallery;
